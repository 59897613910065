<template>
  <div>
    <b-row v-if="!images.length">
      <b-col cols="12">
        Нет сохраненных снимков или скриншотов
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col
        v-for="image in images"
        :key="image.id"
        cols="6"
        md="3"
      >
        <a
          :href="image.store.full_url"
          target="_blank"
        >
          <b-card no-body>
            <b-img-lazy
              fluid-grow
              center
              class="rounded"
              :src="image.store.full_url"
            />
          </b-card>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BImgLazy, BCard,
} from 'bootstrap-vue'

export default {
  name: 'ImagesPreview',
  components: {
    BImgLazy, BRow, BCol, BCard,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      title="Добавление материалов в урок"
      hide-footer
      centered
      no-body
      size="lg"
      dialog-class="large-modal"
      body-class="body-bg"
    >
      <template #default="{ }">
        <b-row style="min-height: 85vh">
          <b-col
            cols="12"
            md="6"
            lg="4"
            xl="3"
          >
            <courses-filter
              class="sticky-top"
              :filter="filter"
              :tabs="tabs"
              @updated="reloadList"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="8"
            xl="9"
          >
            <steps-list
              v-if="filter.tab === 0"
              ref="list"
              :filter="filter"
              selectable
              :selected-steps="selectedSteps.filter(step => !!step.step_id)"
              with-preview
              @onSelect="(st) => $emit('stepSelected', st)"
            />
            <exercises-list
              v-else
              ref="list"
              :filter="filter"
              selectable
              :selected-exercises="selectedSteps.filter(step => !!step.exercise_id)"
              @tagsUpdated="tagsUpdated"
              @subjectSelected="subjectSelected"
              @gradeSelected="gradeSelected"
              @onSelect="(ex) => $emit('stepSelected', ex)"
            />
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BCol } from 'bootstrap-vue'
import StepsList from '@/components/steps/StepsList.vue'
import CoursesFilter from '@/components/new_courses/CoursesFilter.vue'
import ExercisesList from '../../exercise/ExercisesList.vue'

export default {
  name: 'SelectLessonsModal',
  components: {
    ExercisesList,
    CoursesFilter,
    StepsList,
    BModal,
    BRow,
    BCol,
  },
  props: {
    selectedSteps: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    display: false,
    filter: {
      tab: 0,
      own: false,
      types: [],
      subjects: [],
      grades: [],
      courseId: null,
      lessonId: null,
      tags: [],
    },
    tabs: [
      {
        name: 'steps',
        title: 'Карточки',
      },
      {
        name: 'exercises',
        title: 'Упражнения',
      },
    ],
  }),
  methods: {
    reloadList() {
      setTimeout(() => {
        this.$refs.list.load()
      })
    },
    tagsUpdated(tags) {
      const exerciseTags = tags.map(tag => tag.tags.id)
      this.filter.tags = [...exerciseTags]
      this.reloadList()
    },
    subjectSelected(subjectId) {
      if (!this.filter.subjects.includes(subjectId)) this.filter.subjects.push(subjectId)
      this.reloadList()
    },
    gradeSelected(gradeId) {
      if (!this.filter.grades.includes(gradeId)) this.filter.grades.push(gradeId)
      this.reloadList()
    },
    show() {
      this.display = true
    },
    close() {
      this.display = false
    },
  },
}
</script>

<style scoped lang="scss">

</style>

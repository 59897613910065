<template>
  <b-card
    img-alt="course card"
    class="mb-1"
    no-body
    :border-variant="selected ? 'primary' : ''"
    :class="{'cursor-pointer': selectable}"
  >
    <b-card-body
      style="min-height: 100px"
      class="d-flex flex-fill full-height"
    >
      <div
        v-if="draggable"
        class="step-drag-handler"
      >
        <feather-icon
          icon="MenuIcon"
          size="20"
        />
      </div>
      <div class="d-flex flex-column  flex-fill">
        <div class="d-flex align-items-start">
          <div>
            <router-link :to="{ name: 'cabinet.exercise', params: { exercise_id: exercise.id } }">
              <b-card-title class="mb-50 d-flex align-items-center">
                <span>{{ exercise.name }}</span>
              </b-card-title>
            </router-link>
            <b-card-text class="mb-50">
              <div
                v-if="!inSession"
                class="d-flex align-items-center"
              >
                <div class="mr-50">
                  <b-badge
                    v-if="!exercise.disabled"
                    variant="light-success"
                  >
                    <feather-icon icon="EyeIcon" />
                    Активно
                  </b-badge>
                  <b-badge
                    v-else
                    variant="light-muted"
                  >
                    <feather-icon icon="EyeOffIcon" />
                    Скрыто
                  </b-badge>
                </div>
                <div>
                  <b-badge
                    v-if="!exercise.private"
                    variant="light-info"
                  >
                    <feather-icon icon="UnlockIcon" />
                    Публичное
                  </b-badge>
                  <b-badge
                    v-else
                    variant="light-warning"
                  >
                    <feather-icon icon="LockIcon" />
                    Личное
                  </b-badge>
                </div>
              </div>
              <b-badge
                v-else
                variant="light-success"
              >
                Упражнение
              </b-badge>
            </b-card-text>

          </div>
          <a
            v-if="selectable"
            href="#"
            style="z-index: 30"
            class="p-50"
            @click.stop.prevent="selectExercise"
          >
            <div class="d-flex align-items-center">
              <span class="mr-1 text-body">
                {{ selected ? 'Отменить' : 'Выбрать' }}
              </span>
              <b-form-checkbox :checked="selected" />
            </div>
          </a>
          <b-dropdown
            v-else
            no-caret
            dropleft
            variant="flat-secondary"
            class="mx-0 mb-0 p-0 course-selector-list-item-button ml-auto"
            menu-class="dropdown-editable-list"
            toggle-class="p-50 rounded-pill"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
              />
            </template>
            <router-link :to="{ name: 'cabinet.exercise', params: { exercise_id: exercise.id } }">
              <b-dropdown-item-button variant="primary">
                <feather-icon
                  icon="ExternalLinkIcon"
                  size="14"
                  class="mr-1"
                />
                Подробнее
              </b-dropdown-item-button>
            </router-link>
            <create-exercise-modal :exercise="exercise">
              <template #activator="{show}">
                <b-dropdown-item-button @click="show">
                  <feather-icon
                    icon="EditIcon"
                    size="14"
                    class="mr-1"
                  />
                  Настроить
                </b-dropdown-item-button>
              </template>
            </create-exercise-modal>
            <b-dropdown-divider v-if="canEditExercise" />
            <b-dropdown-item-button
              v-if="canEditExercise"
              @click="toggleDisabled"
            >
              <feather-icon
                :icon="exercise.disabled ? 'EyeIcon' : 'EyeOffIcon'"
                size="14"
                class="mr-1"
              />
              <span v-if="exercise.disabled">Сделать активным</span>
              <span v-else>Скрыть</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="canEditExercise"
              @click="togglePrivate"
            >
              <feather-icon
                :icon="!exercise.private ? 'LockIcon' : 'UnlockIcon'"
                size="14"
                class="mr-1"
              />
              <span v-if="!exercise.private">Сделать личным</span>
              <span v-else>Сделать публичным</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="canDeleteExercise"
              variant="danger"
              @click.stop="deleteExercise"
            >
              <feather-icon
                icon="TrashIcon"
                size="14"
                class="mr-1"
              />
              {{ $t('components.button.labels.delete') }}
            </b-dropdown-item-button>
          </b-dropdown>
        </div>

        <div class="flex-fill" />

        <b-card-text>
          <div class="d-flex align-items-center flex-wrap">
            <b-button
              v-if="exercise.subjects"
              size="sm"
              class="mr-50"
              variant="outline-secondary"
              @click="$emit('subjectSelected', exercise.subjects_id)"
            >
              {{ exercise.subjects.text }}
            </b-button>
            <b-button
              v-if="exercise.grade"
              size="sm"
              variant="outline-secondary"
              @click="$emit('gradeSelected', exercise.grade_id)"
            >
              {{ exercise.grade.name }} класс
            </b-button>
            <b-button
              v-for="tag in exercise.exercisesTags"
              :key="tag.id"
              size="sm"
              class="mr-50"
              variant="outline-secondary"
              @click="$emit('tagSelected', tag)"
            >
              <span>{{ tag.tags.name }}</span>
            </b-button>

            <div class="flex-fill" />
            <b-avatar
              v-if="exercise.user"
              size="31"
              :src="exercise.user.avatar"
              class="badge-minimal"
              :title="exercise.user.username"
            />
            <b-button
              v-if="removable"
              size="sm"
              variant="flat-danger"
              @click="$emit('onRemove', exercise)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              Удалить
            </b-button>
            <b-button
              v-else
              size="sm"
              variant="flat-primary"
              :to="{ name: 'cabinet.exercise', params: { exercise_id: exercise.id } }"
            >
              <feather-icon
                icon="ExternalLinkIcon"
                class="mr-50"
              />
              Подробнее
            </b-button>
          </div>
        </b-card-text>
      </div>
    </b-card-body>

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Упражнение ${exercise.name} будет удалено.`"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCardText, BAvatar, BButton, BDropdown, BDropdownItemButton, BDropdownDivider, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ConfirmModal from '@/components/page-elements/modals/ConfirmModal.vue'
import { adminRole, teacherRole, userRoles } from '../../store/user'
import CreateExerciseModal from './exercise/CreateExerciseModal.vue'

export default {
  name: 'Exercise',
  components: {
    CreateExerciseModal,
    ConfirmModal,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BDropdownDivider,
    BBadge,
    BFormCheckbox,
  },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    inSession: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMouseOver: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      userType: 'course/get_course_user_type',
      readyType: 'course/get_course_ready_type',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isAdmin() {
      return this.userRole === adminRole
    },
    canEditExercise() {
      if (this.isAdmin) return true
      return this.exercise.user_id === this.user.id && this.$can('edit', 'exercises')
    },
    canDeleteExercise() {
      if (this.isAdmin) return true
      return this.exercise.user_id === this.user.id && this.$can('delete', 'exercises')
    },
  },
  methods: {
    selectExercise() {
      this.$emit('onSelect', this.exercise)
    },
    deleteExercise() {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$http.delete(`/exercises/delete?id=${this.exercise.id}`)
              .then(() => {
                this.$emit('deleted', this.exercise)
              })
          }
        })
    },
    async toggleDisabled() {
      await this.$http.put(`/exercises/update?id=${this.exercise.id}`, { ...this.exercise, disabled: !this.exercise.disabled })
      this.exercise.disabled = !this.exercise.disabled
    },
    async togglePrivate() {
      await this.$http.put(`/exercises/update?id=${this.exercise.id}`, { ...this.exercise, private: !this.exercise.private })
      this.exercise.private = !this.exercise.private
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  min-height: 150px;
}
</style>

<template>
  <b-card
    v-if="display"
    class="card-fullscreen"
    no-body
  >
    <div
      class="sticky-top card mb-0 shadow-lg py-1"
      style="top: 0"
    >
      <div class="container">
        <div class="d-flex align-items-center ">
          <b-button
            variant="flat-secondary"
            pill
            class="btn-icon mr-1"
            title="Назад"
            size="sm"
            @click="$emit('onClose')"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="25"
            />
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mr-1"
            :to="{name: 'cabinet.dashboard'}"
          >
            В кабинет
          </b-button>
          <h3
            v-if="currentStepName"
            class="mx-1 mb-0"
          >
            {{ currentStepName }}
          </h3>
          <div class="flex-fill" />
          <h3 class="mb-0  mr-2">
            Задание {{ currentStepIndex + 1 }} из {{ steps.length }}
          </h3>
          <b-button
            v-if="currentStepIndex !== 0"
            variant="outline-primary"
            class="ml-1"
            @click="currentStepIndex -= 1"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            Пред.
          </b-button>
          <b-button
            v-if="currentStepIndex !== steps.length - 1"
            variant="primary"
            class="ml-1"
            @click="currentStepIndex += 1"
          >
            Следующее
            <feather-icon
              class="ml-50"
              icon="ArrowRightIcon"
            />
          </b-button>
          <b-button
            v-else
            variant="success"
            class="ml-1"
            @click="finishHometask"
          >
            <feather-icon
              class="mr-50"
              icon="CheckIcon"
            />
            Завершить
          </b-button>
        </div>
      </div>
    </div>
    <div
      v-if="currentStep"
      :key="stepKey"
      class="container"
    >
      <step-editor
        v-if="currentStep.step_id"
        no-header
        :step="currentStep.step"
        images-as-board
        :hometask-id="parseInt(hometask.id)"
      />
      <stream-exercise-questions
        v-else-if="currentStep.exercise_id"
        :exercise-questions="currentStep.exercise.exercisesContents"
        in-step
        :hometask-id="hometask.id"
        :should-save-result="!taskIsNotActive"
      />

      <div class="pt-2 pb-3 d-flex justify-content-end">
        <b-button
          v-if="currentStepIndex !== 0"
          variant="outline-primary"
          class="ml-1"
          @click="currentStepIndex -= 1"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          Назад
        </b-button>
        <b-button
          v-if="currentStepIndex !== steps.length - 1"
          variant="primary"
          class="ml-1"
          @click="currentStepIndex += 1"
        >
          Далее
          <feather-icon
            class="ml-50"
            icon="ArrowRightIcon"
          />
        </b-button>
        <b-button
          v-else
          variant="success"
          class="ml-1"
          @click="finishHometask"
        >
          <feather-icon
            class="mr-50"
            icon="CheckIcon"
          />
          {{ taskIsNotActive ? 'Закрыть' : 'Завершить' }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton,
} from 'bootstrap-vue'
import StepEditor from '../new_courses/course/theme/lesson/step/StepEditor.vue'
import StreamExerciseQuestions from '../video-stream/actions/StreamExerciseQuestions.vue'
import { HOMETASK_STATUS_ACTIVE } from '../../shared/constants'

export default {
  name: 'HometaskFullscreenStepper',
  components: {
    StreamExerciseQuestions,
    StepEditor,
    BCard,
    BButton,
  },
  props: {
    hometask: {
      type: Object,
      required: true,
    },
    display: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    currentStepIndex: 0,
    stepKey: 0,
  }),
  computed: {
    steps() {
      return this.hometask.homeTasksRecords || []
    },
    currentStep() {
      return this.steps[this.currentStepIndex]
    },
    taskIsNotActive() {
      return this.hometask.status !== HOMETASK_STATUS_ACTIVE
    },
    currentStepName() {
      if (this.currentStep.step_id) return this.currentStep?.step?.name
      if (this.currentStep.exercise_id) return this.currentStep?.exercise?.name

      return null
    },
  },
  watch: {
    currentStepIndex() {
      this.stepKey += 1
    },
  },
  methods: {
    finishHometask() {
      this.$emit('onFinish')
    },
  },
}
</script>

<style scoped>

</style>

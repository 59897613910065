<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="9"
      >
        <div class="mb-2 w-100">
          <b-form
            v-if="!loading"
            class="d-flex align-items-center "
            @submit.prevent="toggleNameEdit"
          >
            <div
              v-if="editName"
              class="flex-fill"
            >
              <b-form-group class="mb-0 ">
                <b-form-input
                  v-model="hometask.name"
                  required
                  placeholder="Введите тему домашнего занятия"
                />
              </b-form-group>
            </div>
            <template v-else>
              <h3 class="mb-0">
                <span v-if="hometask.order">№ {{ hometask.order }} |</span> {{ hometask.name || 'Без названия' }}
              </h3>
            </template>
            <b-button
              v-if="editable"
              size="sm"
              pill
              variant="outline-primary"
              class="btn-icon ml-1"
              type="submit"
            >
              <feather-icon :icon="editName ? 'CheckIcon' : 'Edit2Icon' " />
            </b-button>
          </b-form>
          <div v-else>
            <skeletor
              width="300px"
              height="32px"
              class="mb-50"
            />
            <div class="d-flex align-items-center">
              <skeletor
                width="120px"
                height="16px"
                class="mr-50"
              />
              <skeletor
                width="250px"
                height="12px"
              />
            </div>
          </div>

          <div
            v-if="hometask.id"
            class="d-flex align-items-center mt-1"
          >
            <hometask-status-badge
              :status="hometask.status"
              class="mr-1"
            />
            <span class="text-muted">
              Последнее изменение - {{ hometask.updated || hometask.created | dateTime }}
            </span>
          </div>
        </div>

        <div class="thin-divider my-2" />

        <b-tabs v-model="currentTab">
          <b-tab title="Карточки">
            <hometask-records
              ref="hometaskRecords"
              :hometask="hometask"
              :editable="editable"
              :loading="loading"
            />
          </b-tab>
          <b-tab title="Результаты упражнений" />
          <b-tab title="Сохраненные задания">
            <images-preview
              ref="sessionStore"
              :images="hometask.homeTaskStore || []"
              :loading="loading"
            />
          </b-tab>
        </b-tabs>

        <hometask-fullscreen-stepper
          :hometask="hometask"
          :display="showStepper"
          @onFinish="finishHometask"
          @onClose="() => showStepper = false"
        />
      </b-col>

      <b-col
        cols="12"
        xl="3"
      >
        <div
          class="sticky-top"
          style="top: 105px"
        >
          <div
            v-if="!loading "
            class="d-flex align-items-center"
          >
            <div
              v-if="!isTeacher && (isActive || isReadyToCheck)"
              class="flex-fill mb-2 "
            >
              <b-button
                v-if="isActive"
                :variant="isActive ? 'success' : 'outline-secondary'"
                block
                :disabled="!isActive"
                @click="showStepper = true"
              >
                <feather-icon icon="PlayIcon" />
                Начать
              </b-button>
              <b-button
                v-else-if="isReadyToCheck"
                variant="primary"
                block
                @click="showStepper = true"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                Просмотреть
              </b-button>
            </div>

            <div
              v-else-if="hometaskId && isOwner"
              class="mb-2 flex-fill"
            >
              <b-button
                variant="outline-danger"
                :disabled="loading || saving"
                title="Удалить"
                block
                @click="deleteLesson"
              >
                <feather-icon icon="XIcon" />
                Удалить
              </b-button>
            </div>
          </div>

          <div
            v-else
            class="mb-2 d-flex align-items-center"
          >
            <div class="flex-fill">
              <skeletor
                width="100%"
                height="36px"
              />
            </div>

            <skeletor
              class="ml-1"
              width="36px"
              height="36px"
            />
          </div>

          <session-student-card
            v-if="hometask.student_id"
            class="mb-2"
            :student="hometask.student"
            full-width
          />

          <b-card v-if="isOwner">
            <b-card-title>Статус</b-card-title>
            <b-card-text>
              <b-form-group>
                <template v-if="loading">
                  <skeletor
                    v-for="skeleton in 4"
                    :key="`status-skel-${skeleton}`"
                    width="100%"
                    height="20px"
                    class="mb-50"
                  />
                </template>
                <template v-else>
                  <b-form-radio-group
                    v-model="newStatus"
                    stacked
                  >
                    <b-form-radio
                      v-for="status in statuses"
                      :key="status.value"
                      :value="status.value"
                      class="mb-50"
                    >
                      {{ status.title }}
                    </b-form-radio>
                  </b-form-radio-group>
                </template>
              </b-form-group>
              <b-button
                v-if="hometask.status !== newStatus"
                variant="success"
                :disabled="loading || saving"
                block
                @click="saveHometask"
              >
                <template v-if="saving">
                  <b-spinner small />
                </template>
                <template v-else>
                  <feather-icon icon="CheckIcon" />
                  Сменить статус
                </template>
              </b-button>
            </b-card-text>
          </b-card>

          <b-card v-if="editable || hometask.description">
            <b-card-title>Комментарий</b-card-title>
            <b-card-text>
              <b-form-group>
                <skeletor
                  v-if="loading"
                  width="100%"
                  height="90px"
                />
                <b-form-textarea
                  v-else-if="editable"
                  v-model="hometask.description"
                  :readonly="!editable"
                  no-resize
                  rows="3"
                  placeholder="Введите текст комментария"
                  @blur="saveIfEditable()"
                />
                <p v-else>
                  {{ hometask.description }}
                </p>
              </b-form-group>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Это занятие будет удалено`"
    />
  </div>
</template>

<script>
import {
  BSpinner, BTab, BTabs,
  BCard, BCardTitle, BCardText, BFormRadioGroup, BFormRadio, BFormTextarea,
  BRow, BCol, BFormGroup, BForm, BFormInput, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import {
  HOMETASK_STATUS_ACTIVE,
  HOMETASK_STATUS_ACTIVE_LABEL,
  HOMETASK_STATUS_CHECK,
  HOMETASK_STATUS_CHECK_LABEL,
  HOMETASK_STATUS_DONE,
  HOMETASK_STATUS_DONE_LABEL,
  HOMETASK_STATUS_INACTIVE,
  HOMETASK_STATUS_INACTIVE_LABEL,
} from '../../shared/constants'
import ConfirmModal from '../../components/page-elements/modals/ConfirmModal.vue'
import HometaskRecords from '../../components/hometasks/HometaskRecords.vue'
import HometaskStatusBadge from '../../components/hometasks/HometaskStatusBadge.vue'
import SessionStudentCard from '../../components/session/editor/students/SessionStudentCard.vue'
import { studentRole, teacherRole, userRoles } from '../../store/user'
import HometaskFullscreenStepper from '../../components/hometasks/HometaskFullscreenStepper.vue'
import ImagesPreview from '../../components/session/editor/ImagesPreview.vue'

export default {
  name: 'Hometask',
  components: {
    ImagesPreview,
    HometaskFullscreenStepper,
    SessionStudentCard,
    HometaskStatusBadge,
    HometaskRecords,
    ConfirmModal,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BFormRadioGroup,
    BFormRadio,
    BSpinner,
    BFormTextarea,
    BTab,
    BTabs,
  },
  data: () => ({
    currentTab: 0,
    hometask: {
      homeTasksRecords: [],
    },
    editName: false,
    loading: false,
    saving: false,
    newStatus: null,
    showStepper: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    hometaskId() {
      return this.$route.params.task_id
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
    expand() {
      return 'student.usersStudent,'
          + 'homeTasksRecords.step.stepElements.image,'
          + 'homeTasksRecords.exercise.exercisesContents,'
          + 'homeTaskStore'
    },
    isOwner() {
      return this.hometask.teacher_id === this.user.id
    },
    editable() {
      return ![HOMETASK_STATUS_DONE, HOMETASK_STATUS_CHECK].includes(this.hometask.status) && this.isOwner
    },
    isActive() {
      return this.hometask.status === HOMETASK_STATUS_ACTIVE
    },
    isReadyToCheck() {
      return this.hometask.status === HOMETASK_STATUS_CHECK
    },
    statuses() {
      return [
        {
          title: HOMETASK_STATUS_INACTIVE_LABEL,
          value: HOMETASK_STATUS_INACTIVE,
        },
        {
          title: HOMETASK_STATUS_ACTIVE_LABEL,
          value: HOMETASK_STATUS_ACTIVE,
        },
        {
          title: HOMETASK_STATUS_CHECK_LABEL,
          value: HOMETASK_STATUS_CHECK,
        },
        {
          title: HOMETASK_STATUS_DONE_LABEL,
          value: HOMETASK_STATUS_DONE,
        },
      ]
    },
  },
  watch: {
    'hometask.status': {
      handler(newValue) {
        this.newStatus = newValue
      },
    },
  },
  async created() {
    this.hometask.teacher_id = this.user.id

    if (this.hometaskId) {
      this.loading = true
      const hometask = await this.$http.get(`/home-tasks/view?id=${this.hometaskId}`, {
        params: {
          expand: this.expand,
        },
      }).catch(error => {
        if (error.status === 404) this.$router.push({ name: 'cabinet.hometasks' })
      })
      this.hometask = { ...hometask }
      this.loading = false

      if (this.isStudent && this.isActive) {
        setTimeout(() => {
          this.showStepper = true
        }, 200)
      }
    } else {
      await this.saveHometask()
    }
  },
  methods: {
    toggleNameEdit() {
      if (this.editName) {
        this.saveIfEditable()
      } else {
        this.editName = !this.editName
      }
    },
    async saveIfEditable() {
      if (this.editable) await this.saveHometask()
    },
    async saveHometask() {
      this.saving = true
      const { id: hometaskId, name, description } = this.hometask

      const data = {
        name, description, teacher_id: this.hometask.teacher_id || this.user.id, status: this.newStatus,
      }

      if (!hometaskId) {
        const {
          id, updated, created, status,
        } = await this.$http.post('/home-tasks/create', { ...data, status: HOMETASK_STATUS_INACTIVE })
        this.hometask = {
          ...this.hometask, id, updated, created, status,
        }
        this.$router.push({ name: 'cabinet.hometasks.task', params: { task_id: id } })
      } else {
        const { updated, status } = await this.$http.put(`/home-tasks/update?id=${this.hometaskId}`, data).catch(() => {
          this.saving = false
        })
        this.hometask = { ...this.hometask, updated, status }
      }

      this.saving = false
      this.editName = false
    },
    deleteLesson() {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$http.delete(`/home-tasks/delete?id=${this.hometask.id}`).then(() => {
              this.$router.push({ name: 'cabinet.hometasks' })
            })
          }
        })
    },
    finishHometask() {
      this.newStatus = HOMETASK_STATUS_CHECK
      this.showStepper = false
      this.saveHometask()
    },
  },
}
</script>

<style scoped>

</style>

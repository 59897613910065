<template>
  <div class="mb-1">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <h4 class="mb-0">
        Материалы
      </h4>
      <div class="flex-fill" />
      <select-lessons-modal
        ref="selectionModal"
        :selected-steps="selectedRecords"
        @stepSelected="selectRecord"
      >
        <template #activator="{show}">
          <b-button
            v-if="editable"
            size="sm"
            variant="primary"
            @click="show"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            Добавить
          </b-button>
        </template>
      </select-lessons-modal>
    </div>
    <b-row v-if="loading">
      <b-col
        v-for="skel in 2"
        :key="`lesson-skel-${skel}`"
        cols="12"
      >
        <b-card>
          <div class="d-flex align-items-center">
            <skeletor
              width="180px"
              height="24px"
            />
            <div class="flex-fill" />
            <skeletor
              width="24px"
              height="24px"
            />
          </div>

          <div class="d-flex align-items-center mt-1">
            <skeletor
              width="100px"
              height="16px"
              class="mr-1"
            />
            <skeletor
              width="150px"
              height="15px"
              class="mr-1"
            />
          </div>

          <div class="d-flex align-items-end mt-1">
            <skeletor
              v-for="skel in 3"
              :key="`image-skel-${skel}`"
              width="120px"
              height="80px"
              class="mr-1"
            />
            <div class="flex-fill" />
            <skeletor
              width="140px"
              height="32px"
            />
          </div>

        </b-card>
      </b-col>
    </b-row>
    <b-row v-else-if="hometaskRecords.length">
      <b-col cols="12">
        <draggable
          v-model="hometaskRecords"
          tag="div"
          handle=".step-drag-handler"
          v-bind="dragOptions"
          ghost-class="ghost"
          chosen-class="chosen"
          drag-class="drag"
          :force-fallback="true"
          @start="drag = true"
          @end="drag = false"
          @change="saveRecords()"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <div
              v-for="(record, index) in hometaskRecords"
              :key="`record-${index}`"
            >
              <step-item
                v-if="record.step_id"
                :step="record.step"
                :draggable="editable"
                :removable="editable"
                in-session
                @remove="removeSelectedRecord(record)"
              />
              <exercise
                v-else-if="record.exercise_id"
                :draggable="editable"
                :exercise="record.exercise"
                :removable="editable"
                in-session
                @onRemove="removeSelectedRecord(record)"
              />
            </div>
          </transition-group>
        </draggable>
      </b-col>
    </b-row>
    <b-row
      v-else
      class="mb-2"
    >
      <b-col cols="12">
        <div class="d-flex justify-content-center text-center">
          К занятию не добавлены материалы
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import SelectLessonsModal from '@/components/session/editor/SelectLessonsModal.vue'
import StepItem from '@/components/steps/StepItem.vue'
import Exercise from '../exercise/Exercise.vue'

export default {
  name: 'HometaskRecords',
  components: {
    Exercise,
    StepItem,
    SelectLessonsModal,
    BCard,
    BRow,
    BCol,
    BButton,
    draggable,
  },
  props: {
    hometask: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    stepsToDetach: [],
    drag: false,
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'steps',
        disabled: false,
      }
    },
    selectedRecords() {
      return this.hometaskRecords
    },
    hometaskRecords: {
      get() {
        return this.hometask.homeTasksRecords
      },
      set(newValue) {
        this.hometask.homeTasksRecords = newValue
      },
    },
  },
  methods: {
    async selectRecord(selectedRecord) {
      const recordData = { ...selectedRecord } // {step_id?, exercise_id?, step?, exercise?, toDelete}
      const existingRecordKey = Object.keys(this.hometaskRecords).find(key => {
        const { step_id: stepId, exercise_id: exerciseId } = this.hometaskRecords[key]
        return (stepId && stepId === recordData.step_id) || (exerciseId && exerciseId === recordData.exercise_id)
      })
      if (existingRecordKey === undefined) {
        const savedRecord = await this.saveRecord(recordData, this.hometaskRecords.length - 1)
        this.hometaskRecords.push({ ...savedRecord })
      } else if (recordData.toDelete) {
        await this.removeSelectedRecord(recordData)
      }

      this.$refs.selectionModal.close()
    },
    async removeSelectedRecord(record) {
      const records = this.hometaskRecords
      const existingRecordKey = Object.keys(records).find(key => (records[key].step_id ? records[key].step_id === record.step_id : records[key].exercise_id === record.exercise_id))
      const existingRecord = records[existingRecordKey]
      if (existingRecord.id) await this.$http.delete(`/home-tasks-records/delete?id=${existingRecord.id}`)
      records.splice(existingRecordKey, 1)
    },
    async saveRecord(record, order) {
      const hometaskRecord = { ...record, home_task_id: this.hometask.id, order }
      if (!hometaskRecord.id) {
        const { id } = await this.$http.post('/home-tasks-records/create', {
          home_task_id: hometaskRecord.home_task_id,
          step_id: hometaskRecord.step_id,
          exercise_id: hometaskRecord.exercise_id,
          order,
        })
        hometaskRecord.id = id
      } else {
        await this.$http.put(`/home-tasks-records/update?id=${hometaskRecord.id}`, hometaskRecord)
      }

      return hometaskRecord
    },
    async saveRecords() {
      this.hometaskRecords = await Promise.all(this.hometaskRecords.map(async (hometaskRecordObj, order) => {
        const hometaskRecord = await this.saveRecord(hometaskRecordObj, order)
        return hometaskRecord
      }))
    },
  },
}
</script>

<style scoped lang="scss">
.chosen {
  opacity: 1!important;
}
.ghost {
  opacity: 0.5!important;
}
</style>

<template>
  <div>
    <tags-autocomplete
      class="mb-1"
      with-search
      :tags="tags"
      @selected="tagsUpdated"
      @searchUpdated="(query) => $emit('searchUpdated', query)"
    />
    <div class="mb-1 d-flex align-items-center">
      <div class="flex-fill" />
      <b-button
        v-if="selectable && exercises.length"
        variant="flat-primary"
        size="sm"
        @click="selectAll"
      >
        <feather-icon
          :icon="areAllSelected ? 'XIcon' : 'CheckIcon'"
          class="mr-50"
        />
        {{ areAllSelected ? 'Отменить все' : 'Выбрать все' }}
      </b-button>
      <b-button
        v-if="selectable && hasSelected"
        variant="primary"
        size="sm"
        class="ml-1"
        @click="sendSelectedExercises"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        Добавить к уроку
      </b-button>
    </div>
    <template v-if="loading">
      <skeletor
        v-for="(skeleton, key) in 4"
        :key="key"
        width="100%"
        height="150"
        class="mb-1"
      />
    </template>
    <template v-else>
      <exercise
        v-for="exercise in exercises"
        :key="exercise.id"
        :exercise="exercise"
        :selectable="selectable"
        :selected="exerciseIsSelected(exercise)"
        @onSelect="selectExercise"
        @deleted="getExercises"
        @tagSelected="tagAdded"
        @subjectSelected="subj => $emit('subjectSelected', subj)"
        @gradeSelected="grade => $emit('gradeSelected', grade)"
      />
      <div class="d-flex justify-content-between">
        <span class="font-medium-3 text-body">
          <span class="text-muted">Всего курсов: {{ meta.totalCount }} </span>
        </span>

        <list-pagination
          v-if="meta.totalCount / meta.perPage > 1"
          :page="meta.currentPage"
          :limit="meta.perPage"
          :total-number="meta.totalCount"
          :pagination-updated="pageChanged"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Exercise from '@/components/exercise/Exercise.vue'
import ListPagination from '@/components/courses/course/ListPagination.vue'
import TagsAutocomplete from '@/components/page-elements/filters/TagsAutocomplete.vue'

export default {
  name: 'ExercisesList',
  components: {
    TagsAutocomplete, ListPagination, Exercise, BButton,
  },
  props: {
    filter: {
      type: Object,
      default: () => ({
        own: false,
        private: false,
        subjects: [],
        grades: [],
        types: [],
        tags: [],
      }),
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectedExercises: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tags: [],
    exercises: [],
    loading: false,
    searchQuery: '',
    meta: {
      currentPage: 1,
      pageCount: 0,
      perPage: 16,
      totalCount: 0,
    },
    exercisesToSelect: [],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    hasSelected() {
      return !!this.exercisesToSelect.filter(exercise => !exercise.toDelete).length
    },
    areAllSelected() {
      return this.exercisesToSelect.filter(exercise => !exercise.toDelete).length === this.exercises.length
    },
  },
  watch: {
    selectedExercises(newValue) {
      this.exercisesToSelect = newValue
    },
    tags(value) {
      this.$emit('tagsUpdated', value)
    },
  },
  mounted() {
    this.getExercises()
  },
  methods: {
    tagAdded(tag) {
      if (!this.tags.find(tg => tg.tags.name === tag.tags.name)) this.tags.push(tag)
    },
    tagsUpdated(tags) {
      this.tags = [...tags]
    },
    pageChanged({ page, limit }) {
      if (page) this.meta.currentPage = page
      if (limit) this.meta.perPage = limit
      this.getExercises()
    },
    load() {
      this.getExercises()
    },
    async getExercises() {
      const {
        $http, filter, meta, searchQuery,
      } = this

      this.loading = true
      const url = filter.own ? '/exercises/current-user-index' : '/exercises'

      const { items, _meta } = await $http.get(url, {
        params: {
          private: filter.private ? 1 : null,
          exercise_types_id: filter.types.join(','),
          subjects_id: filter.subjects.join(','),
          grade_id: filter.grades.join(','),
          tagsId: filter.tags.join(','),
          name: searchQuery || '',
          limit: meta.perPage,
          page: meta.currentPage,
          expand: 'grade,subjects,exercisesTags.tags',
        },
      })
      this.meta.pageCount = _meta.pageCount
      this.meta.totalCount = _meta.totalCount
      this.exercises = items
      this.loading = false
    },
    searchUpdated(query) {
      this.searchQuery = query
      this.getExercises()
    },
    sendSelectedExercises() {
      this.exercisesToSelect.forEach(exercise => {
        if (!this.exerciseIsSelected(exercise)) this.$emit('onSelect', exercise)
      })
    },
    selectExercise(selectedExercise, onlyAdd = false) {
      if (!this.exerciseIsSelected(selectedExercise)) {
        if (this.exercisesToSelect.find(exercise => exercise.exercise_id === selectedExercise.id)) {
          this.exercisesToSelect = this.exercisesToSelect.map(exercise => {
            const st = { ...exercise }
            if (st.exercise_id === selectedExercise.id) st.toDelete = false
            return st
          })
        } else {
          this.exercisesToSelect.push({
            exercise_id: selectedExercise.id,
            exercise: selectedExercise,
            toDelete: false,
          })
        }
      } else if (!onlyAdd) {
        this.exercisesToSelect = this.exercisesToSelect.map(exercise => {
          const st = { ...exercise }
          if (st.exercise_id === selectedExercise.id) st.toDelete = true
          return st
        })
      }
    },
    selectAll() {
      const { areAllSelected } = this
      this.exercises.forEach(exercise => {
        this.selectExercise(exercise, !areAllSelected)
      })
    },
    exerciseIsSelected(exercise) {
      return !!this.exercisesToSelect.find(ex => ex.exercise_id === exercise.id && !ex.toDelete)
    },
  },
}
</script>

<style scoped>

</style>

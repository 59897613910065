<template>
  <div>
    <span v-if="!statusTitle">Нет данных</span>
    <b-badge
      v-else
      :variant="statusColor"
    >
      {{ statusTitle }}
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import {
  HOMETASK_STATUS_INACTIVE_LABEL,
  HOMETASK_STATUS_INACTIVE,
  HOMETASK_STATUS_ACTIVE_LABEL,
  HOMETASK_STATUS_ACTIVE,
  HOMETASK_STATUS_ASSIGNED,
  HOMETASK_STATUS_ASSIGNED_LABEL,
  HOMETASK_STATUS_CHECK_LABEL,
  HOMETASK_STATUS_CHECK, HOMETASK_STATUS_DONE_LABEL, HOMETASK_STATUS_DONE,
} from '@/shared/constants'

export default {
  name: 'HometaskStatusBadge',
  components: { BBadge },
  props: {
    status: {
      type: String,
      default: null,
    },
  },
  computed: {
    statusTitle() {
      return this.statuses.find(status => this.status === status.value)?.title
    },
    statusColor() {
      switch (this.status) {
        case HOMETASK_STATUS_ACTIVE:
          return 'info'
        case HOMETASK_STATUS_ASSIGNED:
          return 'primary'
        case HOMETASK_STATUS_CHECK:
          return 'warning'
        case HOMETASK_STATUS_DONE:
          return 'success'
        case HOMETASK_STATUS_INACTIVE:
        default: return 'secondary'
      }
    },
    statuses() {
      return [
        {
          title: HOMETASK_STATUS_INACTIVE_LABEL,
          value: HOMETASK_STATUS_INACTIVE,
        },
        {
          title: HOMETASK_STATUS_ACTIVE_LABEL,
          value: HOMETASK_STATUS_ACTIVE,
        },
        {
          title: HOMETASK_STATUS_ASSIGNED_LABEL,
          value: HOMETASK_STATUS_ASSIGNED,
        },
        {
          title: HOMETASK_STATUS_CHECK_LABEL,
          value: HOMETASK_STATUS_CHECK,
        },
        {
          title: HOMETASK_STATUS_DONE_LABEL,
          value: HOMETASK_STATUS_DONE,
        },
      ]
    },
  },
}
</script>

<style scoped>

</style>
